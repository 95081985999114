import React from 'react'
import "./index.css";

import Cardset from './Cardset';

export default function AdminDash() {
    return (
        <div>
            <Cardset />
        </div>
    )
}
