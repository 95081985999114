import React from "react";

import Card from "./card";
import CardsetEmp from "./Cardsetemp";
import Loan from "./Loan";

export default function EmpDash() {
	return (
		<>
			<Card/>
			<CardsetEmp/>
			<Loan/>
		</>
	);
}
